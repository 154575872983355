import {
  Alert,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Icon,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { reqReset } from "../data/DataApi";
import { Link as RouterLink } from "react-router-dom";
import { useColorMode } from "../contexts/ColorModeContext";

const Reset = () => {
  const [error, setError] = useState(true);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { labo } = useAuth();
  const { toggleColorMode, mode } = useColorMode();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!error) {
      const form = new FormData(event.currentTarget);
      const email = form.get("email");
      setLoading(true);
      try {
        setMessage({ severity: "success", message: await reqReset(email) });
      } catch (error) {
        setMessage({ severity: "error", message: error });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    setError(!/(.+)@(.+){2,}\.(.+){2,}/.test(event.currentTarget.value));
  };

  return (
    <Box
      sx={{ minHeight: "100vh" }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <IconButton
        onClick={toggleColorMode}
        color="inherit"
        sx={(theme) => ({
          position: "absolute",
          top: theme.spacing(2),
          right: theme.spacing(2),
        })}
      >
        <Icon>{mode === "dark" ? "dark_mode" : "light_mode"}</Icon>
      </IconButton>
      <Paper sx={{ width: "min(90%, 600px)" }}>
        <Stack spacing={2} p={2}>
          <Avatar
            sx={{ width: "100%", height: { xs: 70, sm: 100 } }}
            src={labo.rest_init_logo_labo}
            variant="rounded"
            imgProps={{ style: { objectFit: "contain" } }}
          />
          <Typography
            variant="h5"
            align="center"
            sx={{ textTransform: "uppercase" }}
          >
            {labo.rest_init_nom_labo}
          </Typography>
        </Stack>
        <Divider />
        <Box component="form" onSubmit={handleSubmit} p={2}>
          <Stack spacing={2}>
            <Typography variant="h3" align="center">
              Mot de passe oublié?
            </Typography>
            <Typography>
              Veuillez saisir l'adresse e-mail liée à votre compte afin de
              pouvoir vous envoyer un e-mail avec votre mot de passe.
            </Typography>
            {message && (
              <Collapse in={true}>
                <Alert severity={message.severity}>{message.message}</Alert>
              </Collapse>
            )}
            <TextField
              name="email"
              label="Addresse e-mail"
              helperText={error && "Veuillez entrer un email valide"}
              error={error}
              onChange={handleChange}
              InputProps={{
                startAdornment: <Icon sx={{ mr: 1 }}>email</Icon>,
              }}
            />
            <Button
              disabled={loading || error}
              size="large"
              variant="contained"
              type="submit"
              endIcon={<Icon>reset</Icon>}
            >
              Envoyer le mot de passe
            </Button>
            <Typography>
              <Link component={RouterLink} to="/login">
                Retour à la page de connexion
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default Reset;
