import React from "react";
import { useLocation } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import ResponsiveAppBar from "./ResponsiveAppBar";

function AuthRoute({ children }) {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) return <Navigate to="/login" state={{ from: location }} />;

  return (
    <>
      <ResponsiveAppBar />
      {children}
    </>
  );
}

export default AuthRoute;
