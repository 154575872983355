import { Icon, Input, Stack, useMediaQuery } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from "@mui/x-data-grid";

function DataGridToolbar({ filterRows }) {
  const asRow = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <GridToolbarContainer>
      <Stack
        direction={asRow ? "row" : "column"}
        spacing={2}
        p={2}
        sx={{ width: "100%" }}
      >
        <Input
          sx={{ flexGrow: 1 }}
          placeholder="Recherche"
          startAdornment={<Icon>search</Icon>}
          onChange={filterRows}
        />
        <Stack direction={"row"}>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
}

export default DataGridToolbar;
