import { Grow, Link, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import DataGridToolbar from "../components/DataGridToolbar";
import { useAuth } from "../contexts/AuthContext";
import { reqDossier, serverAddress } from "../data/DataApi";

function Analyses() {
  const { user, labo } = useAuth();
  const [data, setData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([...data]);
  const [pageSize, setPageSize] = useState(10);

  let columns = [
    {
      field: "datetime",
      headerName: "Date",
      type: "date",
      minWidth: 150,
      valueFormatter: (params) =>
        new Date(params.value).toLocaleDateString("fr-FR"),
    },
    {
      field: "numero",
      headerName: "Dossier",
      minWidth: 150,
    },
    {
      field: "patient",
      headerName: "Patient",
      minWidth: 260,
    },
    {
      field: "ddn",
      headerName: "Date de naissance",
      minWidth: 260,
    },
    {
      field: "organisme",
      headerName: "Organisme",
      minWidth: 260,
    },
    {
      field: "statut",
      headerName: "Statut",
      minWidth: 200,
    },
    {
      field: "telechargement",
      headerName: "Télechargé",
      minWidth: 150,
      type: "boolean",
      valueFormatter: (params) => (params.value ? "Oui" : "Non"),
    },
    {
      field: "resultat",
      headerName: "Résultats",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return params.value ? (
          <Link href={`${serverAddress}/${params.value}`}>PDF</Link>
        ) : null;
      },
    },
    {
      field: "facture",
      headerName: "Facture",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return params.value ? (
          <Link href={`${serverAddress}/${params.value}`}>PDF</Link>
        ) : null;
      },
    },
    {
      field: "medecin",
      headerName: "Médecin",
      minWidth: 300,
    },
  ];

  if (user.isOrg) {
    columns = columns.filter(
      (col) => !["medecin", "organisme"].includes(col.field)
    );
  } else {
    columns = columns.filter(
      (col) => !["patient", "organisme", "facture", "ddn"].includes(col.field)
    );
  }

  useEffect(() => {
    reqDossier()
      .then((data) => {
        setData(data ? data : []);
        setFilteredRows(data ? data : []);
      })
      .catch((error) => console.log(error));
  }, []);

  function filterRows(event) {
    const search = event.currentTarget.value.toLowerCase();
    setFilteredRows(
      data.filter((row) => {
        let rowStr = "";
        Object.keys(row).forEach((key) => (rowStr += row[key]));
        return rowStr.toLowerCase().includes(search);
      })
    );
  }

  return (
    <Grow in={true}>
      <Box m={2}>
        <Stack spacing={2} p={2}>
          <Paper sx={{ p: 2 }}>
            <Typography
              variant="h5"
              align="center"
              sx={{ textTransform: "uppercase" }}
            >
              Bienvenue{" "}
              <Box
                component="span"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                })}
              >
                {user.isOrg
                  ? user.rest_org_nom
                  : `${user.rest_pat_prenom} ${user.rest_pat_nom}`}
              </Box>{" "}
              sur le serveur de résultats du{" "}
              <Box
                component="span"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                })}
              >
                {labo.rest_init_nom_labo?.toUpperCase()}
              </Box>
              .
            </Typography>
          </Paper>
          <Paper>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DataGrid
                // localeText={{ filterValueTrue: "Oui", filterValueFalse: "Non" }}
                sx={(theme) => ({
                  "& .MuiDataGrid-columnHeaders": {
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    background: theme.palette.primary.main,
                  },
                })}
                components={{ Toolbar: DataGridToolbar }}
                componentsProps={{ toolbar: { filterRows, columns } }}
                columns={columns}
                rows={filteredRows}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
                pagination
                autoHeight
                disableSelectionOnClick
                disableColumnMenu
              />
            </Box>
          </Paper>
        </Stack>
      </Box>
    </Grow>
  );
}

export default Analyses;
