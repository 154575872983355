const isDev = process.env.NODE_ENV;
const { protocol, host, hostname } = window.location;
export const serverAddress = `${protocol}//${isDev ? hostname : host}`;

const defaultHeaders = new Headers();
defaultHeaders.append("Content-Type", "application/json");

const defaultOpts = {
  headers: defaultHeaders,
  credentials: "include",
};

function req({ uri, body, opts, headers }) {
  for (let key in headers) {
    defaultHeaders.append(key, headers[key]);
  }
  return fetch(serverAddress + "/api" + uri, {
    ...defaultOpts,
    ...opts,
    method: body ? "POST" : "GET",
    body: typeof body === "object" ? JSON.stringify(body) : body,
  });
}

export function getJson({ uri, body, opts }) {
  let ok;
  return new Promise((resolve, reject) => {
    req({ uri, body, opts })
      .then((response) => {
        ok = response.ok;
        return response.text();
      })
      .then((data) => {
        if (ok) {
          try {
            resolve(JSON.parse(data));
          } catch (error) {
            reject(data);
          }
        } else {
          reject(data);
        }
      })
      .catch((error) => reject(error));
  });
}

export function getText({ uri, body, opts }) {
  let ok;
  return new Promise((resolve, reject) => {
    req({ uri, body, opts })
      .then((response) => {
        ok = response.ok;
        return response.text();
      })
      .then((data) => {
        if (ok) {
          try {
            resolve(data);
          } catch (error) {
            reject(data);
          }
        } else {
          reject(data);
        }
      })
      .catch((error) => reject(error));
  });
}

export function reqLogin(username, password) {
  return getJson({ uri: "/login", body: { username, password } });
}

export function reqLogout() {
  return getJson({ uri: "/logout" });
}

export function reqUser() {
  return getJson({ uri: "/user" });
}

export function reqDossier() {
  return getJson({ uri: "/dossier" });
}

export function reqLabo() {
  return getJson({ uri: "/labo" });
}

export function reqReset(email) {
  return getText({ uri: "/reset", body: { email } });
}
