import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const ColorModeContext = createContext(() => ({
  toggleColorMode: () => {},
  mode: "light",
}));

export function useColorMode() {
  return useContext(ColorModeContext);
}

export default function ColorModeProvider({ children }) {
  const isDarkSystem = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState();

  useEffect(() => {
    setMode(isDarkSystem ? "dark" : "light");
  }, [isDarkSystem]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          return prevMode === "dark" ? "light" : "dark";
        });
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            background: {
              default: mode === "light" ? "#eceff1" : "#001E3C",
              paper: mode === "light" ? "#fff" : "#0A1929",
            },
            mode,
          },
        },
        frFR
      ),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}
