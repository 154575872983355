import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import AuthProvider from "./contexts/AuthContext";
import ColorModeProvider from "./contexts/ColorModeContext";
import Analyses from "./pages/Analyses";
import Login from "./pages/Login";
import Reset from "./pages/Reset";

function App() {
  return (
    <ColorModeProvider>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <AuthRoute>
                  <Analyses />
                </AuthRoute>
              }
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
        </Routes>
      </AuthProvider>
    </ColorModeProvider>
  );
}

export default App;
