import {
  Alert,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Icon,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link as RouterLink } from "react-router-dom";
import { useColorMode } from "../contexts/ColorModeContext";

function Login() {
  const { login, error, labo } = useAuth();
  const [loading, setLoading] = useState(false);
  const { toggleColorMode, mode } = useColorMode();

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const form = new FormData(event.currentTarget);
      await login(form.get("login"), form.get("password"));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{ minHeight: "100vh" }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <IconButton
        onClick={toggleColorMode}
        color="inherit"
        sx={(theme) => ({
          position: "absolute",
          top: theme.spacing(2),
          right: theme.spacing(2),
        })}
      >
        <Icon>{mode === "dark" ? "dark_mode" : "light_mode"}</Icon>
      </IconButton>
      <Paper sx={{ width: "min(90%, 600px)" }}>
        <Stack spacing={2} p={2}>
          <Avatar
            sx={{ width: "100%", height: { xs: 70, sm: 100 } }}
            src={labo.rest_init_logo_labo}
            variant="rounded"
            imgProps={{ style: { objectFit: "contain" } }}
          />
          <Typography
            variant="h5"
            align="center"
            sx={{ textTransform: "uppercase" }}
          >
            {labo.rest_init_nom_labo}
          </Typography>
        </Stack>
        <Divider />
        <Box component="form" onSubmit={handleSubmit} p={2} autoComplete="off">
          <Stack spacing={2}>
            <Typography variant="h3" align="center">
              Connexion
            </Typography>
            <Collapse in={!!error}>
              <Alert severity="error">{error}</Alert>
            </Collapse>
            <TextField
              name="login"
              label="Identifiant"
              InputProps={{
                startAdornment: <Icon sx={{ mr: 1 }}>key</Icon>,
              }}
            />
            <TextField
              name="password"
              label="Mot de passe"
              type={"password"}
              InputProps={{
                startAdornment: <Icon sx={{ mr: 1 }}>password</Icon>,
              }}
            />
            <Button
              disabled={loading}
              size="large"
              variant="contained"
              type="submit"
              endIcon={<Icon>login</Icon>}
            >
              Connexion
            </Button>
            <Typography>
              Mode passe oublié?{" "}
              <Link component={RouterLink} to="/reset">
                Cliquez ici
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}

export default Login;
