import { Icon, Stack, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAuth } from "../contexts/AuthContext";
import { useColorMode } from "../contexts/ColorModeContext";

const ResponsiveAppBar = () => {
  const { logout, labo } = useAuth();
  const { toggleColorMode, mode } = useColorMode();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {labo && (
            <Stack spacing={2} direction="row" alignItems="center" flex={1}>
              <Box
                component="img"
                alt="Logo Laboratoire"
                src={labo.rest_init_logo_labo}
                sx={{ height: isMobile ? 45 : 55, borderRadius: "5px" }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: isMobile ? "inherit" : null,
                  textTransform: "uppercase",
                }}
              >
                {labo.rest_init_nom_labo}
              </Typography>
            </Stack>
          )}
          <IconButton onClick={toggleColorMode} color="inherit">
            <Icon>{mode + "_mode"}</Icon>
          </IconButton>
          <Button
            onClick={logout}
            endIcon={<Icon>logout</Icon>}
            color="inherit"
            sx={{ display: { xs: "none", sm: "inherit" } }}
          >
            Déconnexion
          </Button>
          <IconButton
            onClick={logout}
            color="inherit"
            sx={{ display: { xs: "inherit", sm: "none" } }}
          >
            <Icon>logout</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default ResponsiveAppBar;
