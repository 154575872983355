import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { reqLabo, reqLogin, reqLogout, reqUser } from "../data/DataApi";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [labo, setLabo] = useState({});
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!user) {
      setLoading(true);
      reqUser()
        .then((user) => {
          setUser(user);
          navigate("/");
        })
        .catch((error) => {
          setError(pathname === "/login" ? null : error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate(pathname === "/login" ? "/" : pathname);
    }
  }, [user, navigate, pathname]);

  useEffect(() => {
    reqLabo()
      .then((labo) => setLabo(labo))
      .catch((error) => console.log("error", error));
  }, []);

  function login(username, password) {
    reqLogin(username, password)
      .then((user) => setUser(user))
      .catch((error) => setError(error));
  }

  function logout() {
    reqLogout().then(() => setUser(null));
  }

  const value = {
    labo,
    user,
    error,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <Backdrop open={loading}>
          <CircularProgress color="inherit" sx={{ mr: 2 }} />
          <Typography variant="h4" align="center" color="inherit">
            Chargement
          </Typography>
        </Backdrop>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
